<template>
    <PlayGround v-if="$store.state.pk.status === 'playing'" />
    <MatchGround v-if="$store.state.pk.status === 'matching'" />
    <ResultBoard v-if="$store.state.pk.loser != 'none'"></ResultBoard>
    <div class="user-color-a"
        v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.a_id)">
        你出生在蓝色方</div>
    <div class="user-color-b"
        v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.b_id)">
        你出生在红色方</div>
    <!-- 添加备案信息 -->
    <div class="bottom-layer-content">
        <span class="copyright-information">&copy;kobs&nbsp;&nbsp;贪吃蛇学习</span>
        <br>
        <span class="record-information">
            <img class="img-icp" src="@/assets/images/icp.png" alt="辽公网安备">&nbsp;
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=21031102000052" rel="noreferrer" target="_blank">辽公网安备21031102000052</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="http://beian.miit.gov.cn/">辽ICP备2023013389号-1</a>
        </span>
    </div>
</template>

<script>
import PlayGround from '../../components/PlayGround.vue'
import MatchGround from '../../components/MatchGround.vue'
import ResultBoard from '../../components/ResultBoard.vue'
import { onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'

export default {
    components: {
        PlayGround,
        MatchGround,
        ResultBoard,
    },
    setup() {
        const store = useStore();
        const socketUrl = `wss://www.kobs.fun/websocket/${store.state.user.token}/`;

        store.commit("updateLoser", "none");
        store.commit("updateIsRecord", false);

        let socket = null;
        onMounted(() => {
            store.commit("updateOpponent", {
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            })
            socket = new WebSocket(socketUrl);

            socket.onopen = () => {
                // console.log("connected!");
                store.commit("updateSocket", socket);
            }

            socket.onmessage = msg => {
                const data = JSON.parse(msg.data);
                if (data.event === "start-matching") {  // 匹配成功
                    store.commit("updateOpponent", {
                        username: data.opponent_username,
                        photo: data.opponent_photo,
                    });
                    setTimeout(() => {
                        store.commit("updateStatus", "playing");
                    }, 200);
                    store.commit("updateGame", data.game);
                } else if (data.event === "move") {
                    // console.log(data);
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;
                    snake0.set_direction(data.a_direction);
                    snake1.set_direction(data.b_direction);
                } else if (data.event === "result") {
                    console.log(data);
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;

                    if (data.loser === "all" || data.loser === "A") {
                        snake0.status = "die";
                    }
                    if (data.loser === "all" || data.loser === "B") {
                        snake1.status = "die";
                    }
                    store.commit("updateLoser", data.loser);
                }
            }

            socket.onclose = () => {
                // console.log("disconnected!");
            }
        });

        onUnmounted(() => {
            socket.close();
            store.commit("updateStatus", "matching");
        })
    }
}
</script>

<style scoped>
div.user-color-a {
    text-align: center;
    color: lightblue;
    font-size: 30px;
    font-weight: 600;
}

div.user-color-b {
    text-align: center;
    color: lightcoral;
    font-size: 30px;
    font-weight: 600;
}
/* 备案 */
div.bottom-layer-content {
    position: fixed;
    bottom: 0;
    font-size: 14px; 
    width: 100%;
    text-align: center;
    color: rgb(255, 255, 255)
}
div.bottom-layer-content a {
    text-decoration: none;
    font-size: 90%;
    color: rgb(255, 255, 255);
}

div.bottom-layer-content .record-information .img-icp {
    width: 15px;
}
</style>
